import axios from "axios";
import React from "react";
import { useLocation } from 'react-router-dom';
import './../App.css';

const baseURL = process.env.REACT_APP_BASE_URL
const pathURL = "/WebHookManage";

function WebHook() {
    const [webhook, setWebhook] = React.useState(useLocation().state.botObject.webhook);
    const host = useLocation().state.botObject.host;
    const hasWebHook = webhook != null;
    const { botTableId } = useLocation().state.botObject;
    const [spinner, setspinner] = React.useState(false);

    
    React.useEffect(() => {
        getWebHook()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className="Home m-2">
            <div className="card">
                <div className="row">

                    {hasWebHook && <HasWebHook />}
                    {!hasWebHook && !spinner && <HasNotWebHook />}
                    {spinner && <Spinner />}

                </div>
            </div>
        </div>
    );


    function HasWebHook() {
        return (
            <div className="col-Auto m-2">
                <a href={"https://" + host + "/settings/webhook/edit/" + webhook.id} target="_blank">Webhookの設定画面を開く </a>
            </div>
        );
    };
    function HasNotWebHook() {
        const [buttonDisabled, setNoteButtonDisabled] = React.useState(false);
        
        return (
            <div className="row m-2">
                <div className="d-flex align-items-center col-auto align-middle">メンション・リプライのへの返信をするにはWebHookの設定をする必要があります</div>
                <div className="d-flex align-items-center col-auto">
                    <button
                        className="btn btn-primary"
                        disabled={buttonDisabled}
                        onClick={() => onAddWebHookButton()}> WebHook作成 </button>
                </div>
                
            </div>
        );
        function onAddWebHookButton() {
            setNoteButtonDisabled(true);
            setspinner(true);
            axios.post(baseURL + pathURL,
                botTableId,
                { withCredentials: true }
            ).then(
                (response) => {
                    console.info(response.data);
                    setWebhook(response.data);
                    setspinner(false);
                }
            );
        };
    };
    function Spinner() {
        return (
            <div className="col-auto row">
                <div className="col-auto align-items-center d-flex">
                    <div className="spinner-border text-primary" role="status" >
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div className="col-auto align-items-center d-flex">
                    WebHooK登録中
                </div>
            </div>
        );
    };

    function getWebHook() {
        axios.get(baseURL + pathURL,
            {
                withCredentials: true,
                params: {
                    botTableId: botTableId
                }
            },
        ).then(
            (response) => {
                setWebhook(response.data);
            }
        );
    };


};

export default WebHook;