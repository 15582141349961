import axios from "axios";
import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import './../App.css';

const baseURL = process.env.REACT_APP_BASE_URL;
const pathURL = "/NoteReply";

const NoteReply = () => {
    const { botTableId } = useLocation().state.botObject;
    const [noteReplyList, setNoteReplyList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getNoteReplyList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getNoteReplyList = () => {
        setIsLoading(true);
        axios
            .get(baseURL + pathURL, {
                withCredentials: true,
                params: {
                    botTableId: botTableId
                }
            })
            .then((response) => {
                setNoteReplyList(response.data);
                setIsLoading(false);
            });
    };

    return (
        <div className="Home m-2">
            <div className="card">
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                        <NoteReplyItemNew botTableId={botTableId} onAddNote={getNoteReplyList} />
                    </li>
                    <LoadingIndicator isLoading={isLoading} />
                    {noteReplyList.map((noteReplyObject) => (
                        <li key={noteReplyObject.id} className="list-group-item">
                            <NoteReplyItemEdit
                                noteReplyObject={noteReplyObject}
                                onUpdateNote={getNoteReplyList}
                                onDeleteNote={getNoteReplyList}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

const NoteReplyItemNew = ({ botTableId, onAddNote }) => {
    const [noteReplyObject, setNoteReplyObject] = useState({ botTableId, searchStr: "", postStr: "" });
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const handleInputChange = (event) => {
        const { value, name } = event.target;
        setNoteReplyObject({ ...noteReplyObject, [name]: value });
        setIsButtonDisabled(value.length === 0 || noteReplyObject.postStr.length === 0);
    };

    const handleAddClick = () => {
        axios
            .post(baseURL + pathURL, noteReplyObject, { withCredentials: true })
            .then(() => {
                onAddNote();
            });
    };

    return (
        <div className="row">
            <div className="col-auto d-flex align-items-center m-1">返信ノートの追加</div>
            <div className="col d-flex align-items-center m-1">
                <input
                    className="col d-flex align-items-center me-1"
                    type="text"
                    name="searchStr"
                    value={noteReplyObject.searchStr}
                    placeholder="検索条件"
                    onChange={handleInputChange}
                />
                <input
                    className="col d-flex align-items-center ms-1"
                    type="text"
                    name="postStr"
                    value={noteReplyObject.postStr}
                    placeholder="返信ノート"
                    onChange={handleInputChange}
                />
            </div>
            <div className="col-auto d-flex align-items-center m-1">
                <button disabled={isButtonDisabled} className="btn btn-primary" onClick={handleAddClick}>
                    追加
                </button>
            </div>
        </div>
    );
};

const NoteReplyItemEdit = ({ noteReplyObject, onUpdateNote, onDeleteNote }) => {
    const [editedNoteReplyObject, setEditedNoteReplyObject] = useState(noteReplyObject);
    const [isButtonDisabled, setIsButtonDisabled] = useState(
        noteReplyObject.postStr.length === 0 || noteReplyObject.searchStr.length === 0
    );

    const handleInputChange = (event) => {
        const { value, name } = event.target;
        setEditedNoteReplyObject({ ...editedNoteReplyObject, [name]: value });
        setIsButtonDisabled(value.length === 0 || editedNoteReplyObject.postStr.length === 0);
    };

    const handleUpdateClick = () => {
        axios
            .put(baseURL + pathURL, editedNoteReplyObject, { withCredentials: true })
            .then(() => {
                onUpdateNote();
            });
    };

    const handleDeleteClick = () => {
        axios
            .delete(baseURL + pathURL, {
                data: editedNoteReplyObject,
                withCredentials: true
            })
            .then(() => {
                onDeleteNote();
            });
    };

    return (
        <div className="row">
            <div className="col d-flex align-items-center m-1">
                <input
                    className="col d-flex align-items-center me-1"
                    type="text"
                    name="searchStr"
                    value={editedNoteReplyObject.searchStr}
                    onChange={handleInputChange}
                />
                <input
                    className="col d-flex align-items-center ms-1"
                    type="text"
                    name="postStr"
                    value={editedNoteReplyObject.postStr}
                    onChange={handleInputChange}
                />
            </div>
            <div className="col-auto d-flex align-items-center m-1">
                <button disabled={isButtonDisabled} className="btn btn-primary" onClick={handleUpdateClick}>
                    更新
                </button>
            </div>
            <div className="col-auto d-flex align-items-center m-1">
                <button className="btn btn-primary" onClick={handleDeleteClick}>
                    削除
                </button>
            </div>
        </div>
    );
};

const LoadingIndicator = ({isLoading }) => (
    <div className="m-5" style={{ display: isLoading ? 'block' : 'none' }}>
        <div className="row">
            <div className="col-auto">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
            <div className="col-auto align-items-center m-1">
                <p>ノート一覧取得中</p>
            </div>
        </div>
    </div>
);

export default NoteReply;