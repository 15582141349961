import './BotObject.css';
import React from "react";
import { Link } from 'react-router-dom';

function BotObject(props) {
    const { botObject } = props;

    // botObjectがundefinedの場合はnullを返す
    if (!botObject) {
        console.log('BotObject rendered with botObject: undefined');
        return null;
    }

    console.log('BotObject rendered with botObject:', botObject);

    return (
        <div className="card">
            <div className="row g-0">
                <div className="col-auto ">
                    <img className="BotObject rounded float-start m-1" src={botObject.bot.avatarUrl} alt={botObject.bot.username} />
                </div>
                <div className="col d-flex align-items-center">
                    <div>
                        <p className="card-text">{botObject.bot.name}</p>
                        <Link to="/BotManage" state={{ botObject: botObject }}>
                            ボットを管理する
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BotObject;