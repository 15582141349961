import React, { useEffect, useState } from 'react';
import BotObjectList from '../BotObjectList/BotObjectList';
import { fetchBotList } from '../../api/apiClient';
import './BotList.css';

const BotList = () => {
    const [botList, setBotList] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        fetchBotList()
            .then(data => {
                console.log('Fetched bot list:', data);
                setBotList(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching bot list:', error);
                setBotList([]); // エラー時にbotListを空の配列に設定
                setIsLoading(false);
            });
    }, []);

    console.log('Rendering BotList with botList:', botList); // レンダリング時にbotListをログ出力

    return (
        <div>
            {isLoading && (
                <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '200px' }}>
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    <p className="ms-3 text-muted">ボット一覧取得中</p>
                </div>
            )}
            {!isLoading && <BotObjectList bots={botList} />}
        </div>
    );
};

export default BotList;