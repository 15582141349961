import React, { useState, useEffect } from 'react';
import { authenticateUser } from '../../api/apiClient';
import './AuthenticationForm.css';


const AuthenticationForm = () => {
    const [host, setHost] = useState('');

    useEffect(() => {
        setHost('misskey.io');
    }, []);

    const handleAddBotClick = () => {
        authenticateUser(host)
            .then(redirectUrl => {
                window.location.replace(redirectUrl);
            })
            .catch(error => {
                console.error('Error during authentication:', error);
            });
    };

    return (
        <div className="card bg-light mb-3">
            <div className="card-body">
                <h5 className="card-title text-primary">サーバを指定してbotを追加する</h5>
                <div className="input-group mb-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="misskeyのサーバー名"
                        value={host}
                        onChange={(event) => setHost(event.target.value)}
                    />
                    <button className="btn btn-primary" onClick={handleAddBotClick}>
                        認証画面へ
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AuthenticationForm;