import './App.css';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Home from "./Home";
import Callback from "./callback";
import BotManage from "./BotManage/BotManage"

function App() {
  return (
    <BrowserRouter>
      <h1>クソbotメーカー for misskey</h1>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/callbackUrl" element={<Callback />} />
        <Route path="/BotManage" element={<BotManage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;