import React from 'react';
import BotObject from '../BotObject/BotObject';

const BotObjectList = ({ bots = [] }) => {
    console.log('Rendering BotObjectList with bots:', bots);

    // botsがnullまたはundefinedの場合は空の配列[]がデフォルト値として設定される

    if (!bots || bots.length === 0) {
        console.log('No bots found');
        return <p>ボットが見つかりません。</p>;
    }

    console.log('Rendering bots:', bots);

    return (
        <div>
            {bots.map((botObject) => (
				<BotObject key={botObject.botTableId} botObject={botObject} />
            ))}
        </div>
    );
};

export default BotObjectList;