import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

export const fetchBotList = () => {
    return axios.get(`${baseURL}/botList`, { withCredentials: true })
        .then(response => response.data)
        .catch(error => {
            console.error('Error fetching bot list:', error);
            throw error;
        });
};

export const authenticateUser = (host) => {
    return axios.get(`${baseURL}/authenticationRequest`, {
        withCredentials: true,
        params: {
            host: host
        }
    })
    .then(response => response.data)
    .catch(error => {
        console.error('Error during authentication:', error);
        throw error;
    });
};