import axios from "axios";
import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import './../App.css';

// サーバーのベースURL (環境変数から取得)
const baseURL = process.env.REACT_APP_BASE_URL;
// 通常ノートのパス
const pathURL = "/NoteNomal";

const NoteNomal = () => {
    // botTableIdをuseLocationフックから取得
    const { botTableId } = useLocation().state.botObject;

    // 通常ノートの一覧を保持する状態
    const [noteNomalList, setNoteNomalList] = useState([]);
    // ロード中か否かを管理する状態
    const [isLoading, setIsLoading] = useState(true);

    // コンポーネントがマウントされた時に、通常ノート一覧を取得する
    useEffect(() => {
        getNoteNomalList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getNoteNomalList = () => {
        setIsLoading(true);
        axios
            .get(baseURL + pathURL, {
                withCredentials: true,
                params: {
                    botTableId: botTableId
                }
            })
            .then((response) => {
                setNoteNomalList(response.data);
                setIsLoading(false);
            });
    };

    return (
        <div className="Home m-2">
            <div className="card">
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                        <NoteNomalItemNew botTableId={botTableId} onAddNote={getNoteNomalList} />
                    </li>
                    <LoadingIndicator isLoading={isLoading} />
                    {noteNomalList.map((noteNomalObject) => (
                        <li key={noteNomalObject.id} className="list-group-item">
                            <NoteNomalItemEdit
                                noteNomalObject={noteNomalObject}
                                onUpdateNote={getNoteNomalList}
                                onDeleteNote={getNoteNomalList}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

const NoteNomalItemNew = ({ botTableId, onAddNote }) => {
    const [noteNomalObject, setNoteNomalObject] = useState({ botTableId });
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const handleInputChange = (event) => {
        const { value } = event.target;
        setNoteNomalObject({ ...noteNomalObject, postStr: value });
        setIsButtonDisabled(value.length === 0);
    };

    const handleAddClick = () => {
        axios
            .post(baseURL + pathURL, noteNomalObject, { withCredentials: true })
            .then(() => {
                onAddNote();
            });
    };

    return (
        <div className="row">
            <div className="col-auto d-flex align-items-center m-1">通常ノートの追加</div>
            <div className="col d-flex align-items-center m-1">
                <input
                    className="col d-flex align-items-center"
                    type="text"
                    value={noteNomalObject.postStr || ''}
                    placeholder="投稿ノート"
                    onChange={handleInputChange}
                />
            </div>
            <div className="col-auto d-flex align-items-center m-1">
                <button disabled={isButtonDisabled} className="btn btn-primary" onClick={handleAddClick}>
                    追加
                </button>
            </div>
        </div>
    );
};

const NoteNomalItemEdit = ({ noteNomalObject, onUpdateNote, onDeleteNote }) => {
    const [editedNoteNomalObject, setEditedNoteNomalObject] = useState(noteNomalObject);
    const [isButtonDisabled, setIsButtonDisabled] = useState(noteNomalObject.postStr.length === 0);

    const handleInputChange = (event) => {
        const { value } = event.target;
        setEditedNoteNomalObject({ ...editedNoteNomalObject, postStr: value });
        setIsButtonDisabled(value.length === 0);
    };

    const handleUpdateClick = () => {
        axios
            .put(baseURL + pathURL, editedNoteNomalObject, { withCredentials: true })
            .then(() => {
                onUpdateNote();
            });
    };

    const handleDeleteClick = () => {
        axios
            .delete(baseURL + pathURL, {
                data: editedNoteNomalObject,
                withCredentials: true
            })
            .then(() => {
                onDeleteNote();
            });
    };

    return (
        <div className="row">
            <div className="col d-flex align-items-center m-1">
                <input
                    className="col d-flex align-items-center"
                    type="text"
                    value={editedNoteNomalObject.postStr}
                    onChange={handleInputChange}
                />
            </div>
            <div className="col-auto d-flex align-items-center m-1">
                <button disabled={isButtonDisabled} className="btn btn-primary" onClick={handleUpdateClick}>
                    更新
                </button>
            </div>
            <div className="col-auto d-flex align-items-center m-1">
                <button className="btn btn-primary" onClick={handleDeleteClick}>
                    削除
                </button>
            </div>
        </div>
    );
};

const LoadingIndicator = ({ isLoading }) => (
    <div className="m-5" style={{ display: isLoading ? 'block' : 'none' }}>
        <div className="row">
            <div className="col-auto">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
            <div className="col-auto align-items-center m-1">
                <p>ノート一覧取得中</p>
            </div>
        </div>
    </div>
);

export default NoteNomal;