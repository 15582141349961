import './../App.css';
import React from "react";
import { Link, useLocation } from 'react-router-dom'
import NoteNomal from './NoteNormal';
import NoteReply from './NoteReply';
import NoteScript from './NoteScript';
import WebHook from './WebHook';
import BotObject from '../BotObject/BotObject';
import { Tab, Tabs} from "react-bootstrap";

// BotManageコンポーネントは、ボットの管理用UIを描画する
const BotManage = () => {
    // useLocationフックからbotObjectをステートから取得する
    const { botObject } = useLocation().state;
    // タブの状態を管理するステート変数
    const [tabKey, setTabKey] = React.useState("TabNoteNomal");

    // タブが変更された時に呼ばれる関数
    const onChangeTab = (k) => {
        setTabKey(k);
    }

    return (
        <div className="Home">
            <header className="Home-header"></header>
            {/* BotObjectコンポーネントを描画し、botObjectをプロップスとして渡す */}
            <BotObject botObject={botObject} />
            <div className="card m-2">
                <div className="row g-0">
                    <div className="col-Auto m-2">
                        {/* WebHookの設定に関する情報 (現在はコメントアウトされている) */}
                        {/* <div>フォローされた際のフォローバック、メンション・リプライのへの返信をするにはWebHookの設定をする必要があります→
                        <a href={"https://" + botObject.host + "/settings/webhook/new"}>サーバーの設定画面を開く </a></div>
                        <div>設定値</div>
                        <div>URL:{botObject.webhookUrl} <BiClipboard onClick={() => setClipboard(botObject.webhookUrl)}/></div>
                        <div>シークレット:{botObject.webhookSecret} <BiClipboard onClick={() => setClipboard(botObject.webhookSecret)}/></div> */}
                    </div>
                    <div className="col align-self-end m-2">
                        {/* ボット一覧画面へ戻るリンク */}
                        <Link className="float-end" to={"/"}>一覧へもどる</Link>
                    </div>
                </div>
            </div>

            {/* タブを描画するReact Bootstrapのコンポーネント */}
            <Tabs
                className='m-2'
                id="controlled-tab-example"
                activeKey={tabKey}
                onSelect={onChangeTab}
            >
                <Tab eventKey="TabNoteNomal" title="通常ノート">
                    {/* 通常ノートを表示するコンポーネント */}
                    <NoteNomal />
                </Tab>
                <Tab eventKey="TabNoteReply" title="返信ノート">
                    {/* 返信ノートを表示するコンポーネント */}
                    <NoteReply />
                </Tab>
                <Tab eventKey="WebHook" title="WebHook">
                    {/* WebHookを表示するコンポーネント */}
                    <WebHook/>
                </Tab>
                <Tab eventKey="TabScript" title="掛け合いノート">
                    {/* 掛け合いノートを表示するコンポーネント */}
                    <NoteScript/>
                </Tab>
            </Tabs>
        </div>
    );
};

export default BotManage;