import './App.css';
import axios from "axios";
import React from "react";
import { useLocation   } from 'react-router-dom'

const baseURL = process.env.REACT_APP_BASE_URL

function Callback() {

  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const visible = React.useState(true);

  if(query.get("token") != null)
  {
    axios.get(baseURL + "/callbackUrl",
              { withCredentials: true,
                params:{
                  token: query.get("token")
                }
              },
    ).then(
      (response) => 
        {
          console.log(response.data);
          window.location.replace("/");
        }
      )
  }

  
  // const [botList, setBotList] = React.useState(null);
  // React.useEffect(() => {
  //   axios.get(baseURL + pathBotList,
  //              { withCredentials: true,},).then((response) => {
  //     setBotList(response.data);
  //   });
  // }, []);

  return (
    <div className="Home">
    <header className="Home-header">
        <div class="m-5" style={{ display: visible ? "block flow" : "none" }}>
            <div class="row">
                <div class="col-auto">
                    <div class="spinner-border text-primary" role="status" >
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div class="col-auto align-items-center m-1">
                    <p >ボット登録中</p>
                </div>
            </div>
        </div>
    </header >
</div >
  );
}




export default Callback;
