import './App.css';
import React from 'react';
import AuthenticationForm from './AuthenticationForm/AuthenticationForm';
import BotList from './BotList/BotList';

function Home() {
    return (
        <div className="Home">
            <header className="Home-header">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <BotList />
                        </div>
                        <div className="col-md-4">
                            <AuthenticationForm />
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );
}

export default Home;