import axios from "axios";
import React, { useState, useEffect, useMemo, memo } from "react";
import { useLocation } from 'react-router-dom';
import './../App.css';
import './NoteScript.css';
import SelectReact from "react-select";


const baseURL = process.env.REACT_APP_BASE_URL;
const pathURL = "/NoteScript";
const pathBotList = "/botList";
const pathNoteScriptList = "/NoteScriptList";

const NoteScript = () => {
    const { botTableId } = useLocation().state.botObject;
    const [isLoading, setIsLoading] = useState(false);
    const [botList, setBotList] = useState([]);
    const [noteScriptList, setNoteScriptList] = useState([{ scriptTitle: "追加" }]);
    const [noteScript, setNoteScript] = useState();
    const [selectedOption, setSelectedOption] = useState(noteScriptList[0]);

    useEffect(() => {
        fetchBotList();
        fetchNoteScriptList();
        setSelectedOption(noteScriptList[0]);
    }, []);

    const fetchBotList = () => {
        setIsLoading(true);
        axios
            .get(baseURL + pathBotList, {
                params: {
                    botTableId: botTableId
                },
                withCredentials: true
            })
            .then((response) => {
                setBotList(response.data);
                setIsLoading(false);
            });
    };

    const fetchNoteScriptList = () => {
        setIsLoading(true);
        axios
            .get(baseURL + pathNoteScriptList, {
                params: {
                    botTableId: botTableId
                },
                withCredentials: true
            })
            .then((response) => {
                const merged = [{ scriptTitle: "追加" }, ...response.data];
                setNoteScriptList(merged);
                setIsLoading(false);
            });
    };

    const handleSelectedOptionChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        if (selectedOption.id === null) {
            setNoteScript({
                title: {
                    scriptTitle: ""
                },
                scriptText: [
                    {
                        scriptSeqId: 1,
                        botTableId: botTableId,
                        noteText: ""
                    }
                ]
            });
        } else {
            fetchNoteScript(selectedOption.id);
        }
    };

    const fetchNoteScript = (noteScriptId) => {
        setIsLoading(true);
        axios
            .get(baseURL + pathURL, {
                params: {
                    botTableId: botTableId,
                    noteScriptId: noteScriptId
                },
                withCredentials: true
            })
            .then((response) => {
                setNoteScript(response.data);
                setIsLoading(false);
            });
    };

    return (
        <div className="Home m-2">
            <div className="card">
                <div className="row">
                    <div className="col-auto d-flex align-items-center m-3">掛け合いタイトル</div>
                    <SelectReact
                        className="col d-flex align-items-center m-3"
                        instanceId="userSelect"
                        value={selectedOption}
                        onChange={handleSelectedOptionChange}
                        options={noteScriptList}
                        formatOptionLabel={(option) => option.scriptTitle}
                    />
                </div>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                        <NewNoteScript
                            botTableId={botTableId}
                            botList={botList}
                            noteScript={noteScript}
                            onAddNote={fetchNoteScriptList}
                            onUpdateNote={fetchNoteScriptList}
                            onDeleteNote={fetchNoteScriptList}
                        />
                    </li>
                    <LoadingIndicator isLoading={isLoading} />
                </ul>
            </div>
        </div>
    );
};

const NewNoteScript = ({ botTableId, botList, noteScript, onAddNote, onUpdateNote, onDeleteNote }) => {
    const [noteScriptObject, setNoteScriptObject] = useState(
        noteScript || {
            botTableId: botTableId,
            title: {
                scriptTitle: ""
            },
            scriptText: [
                {
                    scriptSeqId: 1,
                    botTableId: botTableId,
                    noteText: ""
                }
            ]
        }
    );

    const botListMap = useMemo(() => {
        const map = new Map();
        botList.forEach((bot, key) => {
            map.set(bot.botTableId, key);
        });
        return map;
    }, [botList]);

    const valueFromBot = (key) => {
        return botList[botListMap.get(noteScriptObject.scriptText[key].botTableId)];
    };

    const handleInputChange = (event, key) => {
        const { value, name } = event.target;
        const updatedScriptText = [...noteScriptObject.scriptText];
        updatedScriptText[key][name] = value;
        setNoteScriptObject({ ...noteScriptObject, scriptText: updatedScriptText });
    };

    const handleAddRow = () => {
        const scriptSeqId = noteScriptObject.scriptText.length + 1;
        const updatedScriptText = [
            ...noteScriptObject.scriptText,
            {
                scriptSeqId: scriptSeqId,
                botTableId: botTableId,
                noteText: ""
            }
        ];
        setNoteScriptObject({ ...noteScriptObject, scriptText: updatedScriptText });
    };

    const handleTitleChange = (event) => {
        const { value } = event.target;
        setNoteScriptObject({ ...noteScriptObject, title: { ...noteScriptObject.title, scriptTitle: value } });
    };

    const handleBotChange = (event, key) => {
        const updatedScriptText = [...noteScriptObject.scriptText];
        updatedScriptText[key].botTableId = event.botTableId;
        setNoteScriptObject({ ...noteScriptObject, scriptText: updatedScriptText });
    };

    const handleAddClick = () => {
        axios
            .post(baseURL + pathURL, noteScriptObject, { withCredentials: true })
            .then(() => {
                onAddNote();
            });
    };

    const handleUpdateClick = () => {
        axios
            .put(baseURL + pathURL, noteScriptObject, { withCredentials: true })
            .then(() => {
                onUpdateNote();
            });
    };

    const handleDeleteClick = () => {
        axios
            .delete(baseURL + pathURL, {
                data: noteScriptObject,
                withCredentials: true
            })
            .then(() => {
                onDeleteNote();
            });
    };

    const FormatOptionLabel = memo(({ option }) => (
        <div className="row">
            <div className="col-auto d-flex align-items-center">
                <img className="DropdownItemBotImg rounded" src={option.bot.avatarUrl} alt={option.bot.username} />
            </div>
            <div className="col-auto d-flex align-items-center">{option.bot.name}</div>
        </div>
    ));

    return (
        <div className="row">
            <div className="col-auto d-flex align-items-center m-1">掛け合いノートの追加</div>
            <div className="col-auto d-flex align-items-center m-1">
                <input
                    className="col d-flex align-items-center"
                    type="text"
                    name="scriptTitle"
                    value={noteScriptObject.title.scriptTitle}
                    placeholder="タイトル"
                    onChange={handleTitleChange}
                />
            </div>
            {noteScriptObject.scriptText.map((scriptText, key) => (
                <div className="row m-1" key={key}>
                    <SelectReact
                        className="col align-items-center m-1"
                        instanceId="userSelect"
                        value={valueFromBot(key)}
                        onChange={(event) => handleBotChange(event, key)}
                        options={botList}
                        formatOptionLabel={(option) => <FormatOptionLabel option={option} />}
                    />
                    <input
                        className="col-auto d-flex align-items-center m-1"
                        type="text"
                        name="noteText"
                        value={noteScriptObject.scriptText[key].noteText}
                        placeholder="投稿ノート"
                        onChange={(event) => handleInputChange(event, key)}
                    />
                </div>
            ))}

            <div className="col-auto d-flex align-items-center m-1">
                <button className="btn btn-primary" onClick={handleAddRow}>
                    行追加
                </button>
            </div>

            {!noteScriptObject.id && (
                <div className="col-auto d-flex align-items-center m-1">
                    <button className="btn btn-primary" onClick={handleAddClick}>
                        追加
                    </button>
                </div>
            )}
            {noteScriptObject.id && (
                <div className="col-auto d-flex align-items-center m-1">
                    <button className="btn btn-primary" onClick={handleUpdateClick}>
                        更新
                    </button>
                </div>
            )}
            {noteScriptObject.id && (
                <div className="col-auto d-flex align-items-center m-1">
                    <button className="btn btn-primary" onClick={handleDeleteClick}>
                        削除
                    </button>
                </div>
            )}
        </div>
    );
};

const LoadingIndicator = ({ isLoading }) => (
    <div className="m-5" style={{ display: isLoading ? 'block' : 'none' }}>
        <div className="row">
            <div className="col-auto">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
            <div className="col-auto align-items-center m-1">
                <p>ノート一覧取得中</p>
            </div>
        </div>
    </div>
);

export default NoteScript;